export function endpoints(url: string, apiVersion: string) {
  return {
    api:                              `${url}/${apiVersion}`,
    auth:                             `${url}/auth`,
    refresh:                          `${url}/auth/refresh`,
    forgottenPassword:                `${url}/auth/forgotten_password`,
    resetPassword:                    `${url}/auth/change_password`,
    logout:                           `${url}/${apiVersion}/refresh_tokens`,
    self:                             `${url}/${apiVersion}/_self`,
    companyUsers:                     `${url}/${apiVersion}/customers`,
    companyBranches:                  `${url}/${apiVersion}/branches`,
    jobTemplates:                     `${url}/${apiVersion}/job_templates`,
    job:                              `${url}/${apiVersion}/jobs`,
    users:                            `${url}/${apiVersion}/users`,
    jobPicture:                       `${url}/${apiVersion}/jobs/picture`,
    jobDuunitoriBanner:               `${url}/${apiVersion}/jobs/duunitori_banner`,
    locations:                        `${url}/${apiVersion}/locations`,
    jobCategories:                    `${url}/${apiVersion}/job_categories`,
    jobEmploymentTypes:               `${url}/${apiVersion}/job_types_of_employment`,
    jobEmploymentTypesAddition:       `${url}/${apiVersion}/job_types_of_employment_addition`,
    candidates:                       `${url}/${apiVersion}/candidates`,
    jobsOverview:                     `${url}/${apiVersion}/jobs/page/overview/company`,
    jobsApplicationOverview:          `${url}/${apiVersion}/jobs/page/customer_data`,
    jobsApplicationOverviewManager:   `${url}/${apiVersion}/jobs/page/enterprise_manager_data/company`,
    comments:                         `${url}/${apiVersion}/application_comments`,
    hiringStatuses:                   `${url}/${apiVersion}/application_hiring_statuses`,
    applications:                     `${url}/${apiVersion}/applications`,
    notes:                            `${url}/${apiVersion}/notes`,
    kpi:                              `${url}/${apiVersion}/kpi`,
    cv:                               `${url}/${apiVersion}/application_ask_for_cv_request`,
    candidateCv:                      `${url}/${apiVersion}/application_documents/context/upload_resume_by_candidate`,
    sms:                              `${url}/${apiVersion}/applications/sms`,
    applicationEmails:                `${url}/${apiVersion}/application_emails`,
    companies:                        `${url}/${apiVersion}/companies`,
    companyHomePage:                  `${url}/${apiVersion}/company_landing_pages`,
    companyWhyWorkWithUs:             `${url}/${apiVersion}/why_work_with_us`,
    universalJobs:                    `${url}/${apiVersion}/universal_jobs`,
    applicationsByHiringStatus:       `${url}/${apiVersion}/applications/hiring_status`,
    publishingPlatforms:              `${url}/${apiVersion}/job_platforms`,
    customers:                        `${url}/${apiVersion}/customers`,
    applicationsHiringStatusTrigger:  `${url}/${apiVersion}/application_hiring_status_triggers`,
    ams: {
      categories:                     `${url}/${apiVersion}/ams_categories`,
      durations:                      `${url}/${apiVersion}/ams_durations`,
      municipalities:                 `${url}/${apiVersion}/ams_municipalities`,
      occupations:                    `${url}/${apiVersion}/ams_occupations`,
      salaries:                       `${url}/${apiVersion}/ams_salaries`,
      timeExtents:                    `${url}/${apiVersion}/ams_time_extents`,
      driving_licences:               `${url}/${apiVersion}/ams_driving_licences`,
      continents:                     `${url}/${apiVersion}/ams_continents`,
      countries:                      `${url}/${apiVersion}/ams_countries`,
    },
    blocket: {
      categories:                     `${url}/${apiVersion}/blocket_categories`,
      subcategories:                  `${url}/${apiVersion}/blocket_subcategories`,
      regions:                        `${url}/${apiVersion}/blocket_regions`,
      municipalities:                 `${url}/${apiVersion}/blocket_municipalities`,
      timeExtents:                    `${url}/${apiVersion}/blocket_time_extents`,
      typeOfEmployment:               `${url}/${apiVersion}/blocket_type_of_employments`,
    },
    finn: {
      occupationGeneral:              `${url}/${apiVersion}/finn_occupations_general`,
      occupationSpecialization:       `${url}/${apiVersion}/finn_occupations_specialization`,
      durations:                      `${url}/${apiVersion}/finn_durations`,
      industries:                     `${url}/${apiVersion}/finn_industries`,
      sectors:                        `${url}/${apiVersion}/finn_sectors`,
      timeExtents:                    `${url}/${apiVersion}/finn_time_extents`,
      workingLanuages:                `${url}/${apiVersion}/finn_working_languages`,
      remoteOptions:                  `${url}/${apiVersion}/finn_remote_options`,
    },
    nav: {
      categories:                     `${url}/${apiVersion}/nav_occupations`,
      engagementTypes:                `${url}/${apiVersion}/nav_engagement_types`,
      extents:                        `${url}/${apiVersion}/nav_extents`,
      jobArrangements:                `${url}/${apiVersion}/nav_job_arrangements`,
      remotes:                        `${url}/${apiVersion}/nav_remotes`,
      sectors:                        `${url}/${apiVersion}/nav_sectors`,
      workDays:                       `${url}/${apiVersion}/nav_work_days`,
      workHours:                      `${url}/${apiVersion}/nav_work_hours`,
      allCategories:                  `${url}/${apiVersion}/nav_categories`
    },
    besoksliv: {
      packages:                       `${url}/${apiVersion}/besoksliv_packages`,
    },
    jobnet: {
      jobAdType:                      `${url}/${apiVersion}/jobnet_job_ad_type`,
      jobAdPropertyType:              `${url}/${apiVersion}/jobnet_job_ad_property_type`,
      employmentType:                 `${url}/${apiVersion}/jobnet_employment_type`,
      weeklyWorktimeType:             `${url}/${apiVersion}/jobnet_weekly_worktime_type`,
      driverLicenseType:              `${url}/${apiVersion}/jobnet_driver_license_type`,
      occupations:                    `${url}/${apiVersion}/jobnet_occupations`,
      municipality:                   `${url}/${apiVersion}/jobnet_municipalities`
    },
    norwegianZipCodes:                `${url}/${apiVersion}/norwegian_zip_codes`,
    allTemplates:                     `${url}/${apiVersion}/templates`,
    smsTemplates:                     `${url}/${apiVersion}/sms_templates`,
    emailTemplates:                   `${url}/${apiVersion}/application_email_templates`,
    emailPreview:                     `${url}/${apiVersion}/email_preview`,
    jobDrafts:                        `${url}/${apiVersion}/job_drafts`,
    jobWorkLocations:                 `${url}/${apiVersion}/job_work_locations`,
    supportEmailSubjects:             `${url}/${apiVersion}/support_email_subjects`,
    supportEmail:                     `${url}/${apiVersion}/support_emails`,
    staffingEmailSubjects:            `${url}/${apiVersion}/staffing_and_recruitment_email_subjects`,
    staffingEmail:                    `${url}/${apiVersion}/staffing_and_recruitment_emails`,
    exportedTilTidEmployees:          `${url}/${apiVersion}/exported_til_tid_employees`,
    tilTidOrganizations:              `${url}/${apiVersion}/til_tid_organization_units`,
    psykometrika:                     `${url}/${apiVersion}/psykometrika`,
    emailResponses:                   `${url}/${apiVersion}/application_email_responses`,
    markEmailResponsesAsRead:         `${url}/${apiVersion}/application_email_responses/were_read_by_employee/batch`,
    enterprises:                      `${url}/${apiVersion}/enterprises`,
    enterpriseWhyWorkWithUs:          `${url}/${apiVersion}/enterprise_why_work_with_us`,
    enterpriseHomePage:               `${url}/${apiVersion}/enterprise_landing_pages`,
    enterpriseUsers:                  `${url}/${apiVersion}/enterprise_managers`,
    pinnedApplications:               `${url}/${apiVersion}/pinned_applications`,
    integration:                      `${url}/${apiVersion}/check_customer`,
    managers:                         `${url}/${apiVersion}/enterpriseManagers`,
    company:                          `${url}/${apiVersion}/company`,
    tiltidCustomer:                   `${url}/${apiVersion}/til_tid_customers`,
    refAppResponses:                  `${url}/${apiVersion}/refapp_reference_checks`,
    refAppReferenceCheck:             `${url}/${apiVersion}/refapp_reference_check`,
    refAppReferenceCheckBatch:        `${url}/${apiVersion}/refapp_reference_check_batch`,
    refAppConfig:                     `${url}/${apiVersion}/refapp_configuration`,
    auth2fa_totp:                     `${url}/auth2fa_totp`,
    auth2fa_email:                    `${url}/auth2fa_email`,
    storage:                          `${url}/${apiVersion}/storage`,
    enterpriseIntroText:              `${url}/${apiVersion}/enterprise_intros`,
    companyIntroText:                 `${url}/${apiVersion}/company_intros`,
    ssnAndFullNameRequests:           `${url}/${apiVersion}/application_ask_for_ssn_and_full_name_requests`,
    sri: {
      recipients:                     `${url}/${apiVersion}/sri_recipients`,
      orderers:                       `${url}/${apiVersion}/sri_orderers`,
      backgroundCheck:                `${url}/${apiVersion}/sri_background_check`,
      attachment:                     `${url}/${apiVersion}/sri_background_check_attachment`,
      notifications:                  `${url}/${apiVersion}/sri_background_checks`,
    },
    bindJobs:                         `${url}/${apiVersion}/bind_jobs`,
    applicationTags:                  `${url}/${apiVersion}/application_tags`,
    verkstadsjobbBrands:              `${url}/${apiVersion}/verkstadsjobb_brands`,
    videoInterviews:                  `${url}/${apiVersion}/video_interviews`,
    videoInterview:                   `${url}/${apiVersion}/video_interview`,
    groupVideoInterview:              `${url}/${apiVersion}/group_video_interview`,
    groupVideoInterviews:             `${url}/${apiVersion}/group_video_interviews`,
    customerInGroupInterview:         `${url}/${apiVersion}/customer_in_group_video_interview`,
    enterpriseManagerInGroupInterview:`${url}/${apiVersion}/enterprise_manager_in_group_video_interview`,
    candidateInGroupInterview:        `${url}/${apiVersion}/candidate_in_group_video_interview`,
    thirdPartyInGroupInterview:       `${url}/${apiVersion}/third_party_in_group_video_interview`,
    adwayBoosts:                      `${url}/${apiVersion}/adway_boosts`,
    adwayValidateExistingJob:         `${url}/${apiVersion}/validate_adway_job_for_existing_job`,
    adwayValidateNewJob:              `${url}/${apiVersion}/validate_adway_job_for_new_job`,
    requisitionForms:                 `${url}/${apiVersion}/requisition_forms`,
    requisitions:                     `${url}/${apiVersion}/requisitions`,
    requisitionNotifications:         `${url}/${apiVersion}/requisition_employee_dashboard_notifications`,
    alvalabsJobs:                     `${url}/${apiVersion}/alvalabs_jobs`,
    alvalabsCandidate:                `${url}/${apiVersion}/alva_labs_candidate`,
    alvaNotifications:                `${url}/${apiVersion}/alva_assessments`,
    alvaBatch:                        `${url}/${apiVersion}/alva_labs_candidate_batch`,
    idCandidateDataDeleted:           `${url}/${apiVersion}/applications_with_candidate_personal_data_removed`,
    whistleBlowerMetadata:            `${url}/${apiVersion}/whistle_blower_metadata`,
    removeStoryVideo:                 `${url}/${apiVersion}/story_video_library_items`,
    removeStoryImage:                 `${url}/${apiVersion}/story_image_library_items`,
    aiSkills:                         `${url}/${apiVersion}/open-ai/skills`,
    agreementForms:                   `${url}/${apiVersion}/agreement_forms`,
    agreements:                       `${url}/${apiVersion}/application_agreements`
  };
}
